<template>
  <div class="h-svh overflow-hidden">
    <div class="flex size-full bg-cultured">
      <!--  N A V I G A T I O N  D E K S T O P  -->
      <div
        class="hidden h-full min-w-250 flex-col justify-between
        rounded-br-20 border-r border-solid border-anti-flash-white bg-white px-30 py-40 xl:flex"
      >
        <div class="flex flex-col">
          <div class="flex items-center gap-x-5">
            <img class="size-28" src="@/assets/icons/logo.svg" alt="logo">
            <p class="font-rubik text-24 font-medium text-primary">BIGLAW</p>
          </div>
          <div class="my-24 h-1 w-full rounded-full bg-american-silver" />
          <div class="flex flex-col gap-y-20">
            <template
              v-for="({icon, text, routeName, disabled}, i) in navigationRoutes"
              :key="i"
            >
              <router-link
                v-if="routeName && !disabled"
                class="flex items-center gap-x-6 text-old-silver"
                :to="{name: routeName}"
                active-class="active-route"
              >
                <img :src="icon" alt="icon" class="route__icon">
                <p class="route__text text-16 font-medium">{{ text }}</p>
              </router-link>
              <span v-else class="flex cursor-not-allowed items-center gap-x-6 text-old-silver opacity-50">
                <img :src="icon" alt="icon" class="route__icon">
                <p class="route__text text-16 font-medium">{{ text }}</p>
              </span>
            </template>
          </div>
        </div>
        <div class="flex flex-col gap-y-20">
          <a href="https://t.me/LLC_Biglaw" target="_blank">
            <el-button link class="flex justify-start">
              <div class="flex items-center justify-start gap-x-6">
                <img src="@/assets/icons/message.svg" alt="icon" class="route__icon">
                <p class="text-16 font-medium text-old-silver">Поддержка</p>
              </div>
            </el-button>
          </a>
          <el-button link class="!ml-0 flex justify-start" @click="logout">
            <div class="flex items-center justify-start gap-x-6">
              <img src="@/assets/icons/logout.svg" alt="icon" class="route__icon">
              <p class="text-16 font-medium text-old-silver">Выйти</p>
            </div>
          </el-button>
        </div>
      </div>
      <!--  N A V I G A T I O N  M O B I L E  -->
      <!--  W I P  -->
      <div
        ref="mobileMenu"
        class="fixed top-0 z-30 h-screen w-[260px] bg-white px-20 py-23
        shadow-[0_0_20px_0_rgba(111,111,111,0.3)] transition-[right] duration-300 ease-out md:w-350 lg:w-450 xl:hidden"
        :class="[useMobileMenu.state.isOpened ? 'right-0' : 'right-[-260px] md:-right-350 lg:-right-450']"
      >
        <div class="flex w-full flex-col">
          <div class="flex flex-col gap-y-16 md:gap-y-18 lg:gap-y-20">
            <div class="flex w-full items-center justify-between gap-x-5">
              <div class="flex items-center gap-x-8">
                <img src="@/assets/icons/person--square.svg" class="size-24 md:size-28 lg:size-36" alt="person">
                <p class="text-16 font-medium text-primary md:text-20 md:font-normal lg:text-28">
                  {{ mainInfo?.name }}
                </p>
              </div>
              <el-button link @click="useMobileMenu.toggle(false)">
                <img class="close-icon size-24 md:size-40 lg:size-44" src="@/assets/icons/close.svg" alt="close-menu">
              </el-button>
            </div>
            <div class="flex items-center gap-x-8">
              <p v-if="mainInfo?.tariff" class="text-16 font-medium text-primary md:text-20 md:font-normal lg:text-28">
                Ваш тариф:<br>
                <router-link
                  class="text-azure"
                  :to="routesNames.tariff"
                  @click="useMobileMenu.toggle(false)"
                >
                  {{ mainInfo.tariff.name }}
                </router-link>
              </p>
            </div>
          </div>
          <Divider class="!my-12 md:my-24 lg:!my-20" />
          <div class="flex flex-col gap-y-20 md:gap-y-28 lg:gap-y-32">
            <template
              v-for="({icon, text, routeName, disabled}, i) in navigationRoutes"
              :key="i"
            >
              <router-link
                v-if="routeName && !disabled"
                class="flex items-center gap-x-6 text-old-silver lg:gap-x-12"
                :to="{name: routeName}"
                active-class="active-route"
                @click="useMobileMenu.toggle(false)"
              >
                <img :src="icon" alt="icon" class="route__icon size-24 md:size-28 lg:size-36">
                <p class="route__text text-16 font-medium md:text-20 md:font-normal lg:text-28">{{ text }}</p>
              </router-link>
              <span
                v-else
                class="flex cursor-not-allowed items-center gap-x-6 text-old-silver opacity-50 lg:gap-x-12"
                @click="useMobileMenu.toggle(false)"
              >
                <img :src="icon" alt="icon" class="route__icon size-24 md:size-28 lg:size-36">
                <p class="route__text text-16 font-medium md:text-20 md:font-normal lg:text-28">{{ text }}</p></span>
            </template>
            <a href="https://t.me/LLC_Biglaw" target="_blank">
              <el-button link class="flex justify-start">
                <div class="flex items-center justify-start gap-x-6 lg:gap-x-12">
                  <img src="@/assets/icons/message.svg" alt="icon" class="route__icon size-24 md:size-28 lg:size-36">
                  <p class="text-16 font-medium text-old-silver md:text-20 md:font-normal lg:text-28">Поддержка</p>
                </div>
              </el-button>
            </a>
            <el-button link class="flex justify-start" @click="logout">
              <div class="flex items-center justify-start gap-x-6 lg:gap-x-12">
                <img src="@/assets/icons/logout.svg" alt="icon" class="route__icon size-24 md:size-28 lg:size-36">
                <p class="text-16 font-medium text-old-silver md:text-20 md:font-normal lg:text-28">Выйти</p>
              </div>
            </el-button>
          </div>
        </div>
      </div>
      <!--  W I P  -->
      <div class="flex w-full flex-col">
        <div
          class="h-75 lg:px-88 -ml-1 flex w-full items-center justify-end border-b border-solid border-anti-flash-white
          bg-white px-[5%] py-12 shadow-left-white-1 md:px-64 md:py-24 xl:rounded-br-20 xl:px-95"
        >
          <div class="mr-24 hidden items-center gap-x-12 xl:flex">
            <p v-if="mainInfo?.tariff" class="font-rubik text-18 font-medium text-primary">
              Ваш тариф:
              <router-link
                class="text-azure"
                :to="routesNames.tariff"
              >
                {{ mainInfo.tariff.name }}
              </router-link>
            </p>
          </div>
          <div class="hidden items-center gap-x-12 xl:flex">
            <img src="@/assets/icons/person.svg" alt="person">
            <p class="font-rubik text-18 font-medium text-primary">{{ mainInfo?.name }}</p>
          </div>
          <div class="z-20 flex w-full items-center justify-between xl:hidden">
            <div class="flex items-center gap-x-5">
              <img class="size-24 md:size-44 lg:size-56" src="@/assets/icons/logo.svg" alt="logo">
              <p class="font-rubik text-20 font-medium text-primary md:text-24 lg:text-36">BIGLAW</p>
            </div>
            <el-button link @click="useMobileMenu.toggle(true)">
              <img class="size-28 md:size-44 lg:size-56" src="@/assets/icons/burger-icon.svg" alt="menu">
            </el-button>
          </div>
        </div>
        <div
          class="size-full overflow-auto scroll-smooth px-20 py-28
          max-xl:self-center md:px-64 md:py-40 lg:px-[88px] lg:py-64 xl:px-68 xl:py-48"
        >
          <router-view />

          <div class="fixed right-16 top-90 hidden flex-col items-end gap-16 overflow-hidden xl:flex">
            <transition-group :name="`fade-top-right`">
              <div
                v-for="notification in notifications"
                :key="notification.id"
                class="relative flex w-[340px] bg-water px-24 py-16 pr-30 text-16 leading-snug"
              >
                <span class="w-full break-words" v-html="notification.message" />
                <el-button link @click="removeNotification(notification)">
                  <img src="@/assets/icons/close-bold.svg" alt="close-icon" class="absolute right-0 top-0">
                </el-button>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { routesNames } from '@/router'
import { onClickOutside } from '@vueuse/core'
import { useAuthStore, useMainInfoStore, useGlobalStore } from '@/stores/shared'

import TariffIcon from '@/assets/icons/tariff.svg'
import HomeIcon from '@/assets/icons/home.svg'
import InstructionIcon from '@/assets/icons/instruction.svg'
import ContractAndPaymentIcon from '@/assets/icons/contract-and-payment.svg'
import LocationIcon from '@/assets/icons/location.svg'
import SurveyIcon from '@/assets/icons/survey.svg'
import DocumentIcon from '@/assets/icons/document.svg'
import Divider from '@/views/survey/components/Divider.vue'
import { ERole } from '@/views/auth/auth.types'
import { ETariffsId } from '@/views/tariff/tariff.types'

const { logout } = useAuthStore()
const { role } = storeToRefs(useAuthStore())
const { getMainInfo } = useMainInfoStore()
const { mainInfo } = storeToRefs(useMainInfoStore())
const { removeNotification } = useGlobalStore()
const { notifications } = storeToRefs(useGlobalStore())

const navigationRoutes = computed(() => {
  return [
    {
      icon: HomeIcon,
      text: 'Главная',
      routeName: routesNames.main
    },
    {
      icon: TariffIcon,
      text: 'Тариф',
      routeName: routesNames.tariff
    },
    {
      icon: InstructionIcon,
      text: 'Инструкции',
      routeName: routesNames.tutorial
    },
    {
      icon: SurveyIcon,
      text: 'Анкета',
      routeName: routesNames.survey,
      disabled: (mainInfo.value?.tariff?.id === ETariffsId.FOR_KEY && role.value === ERole.USER &&
        !mainInfo.value.is_tariff_paid) ||
        !mainInfo.value?.tariff?.id
    },
    {
      icon: ContractAndPaymentIcon,
      text: 'Договор и оплаты',
      routeName: routesNames.contract
    },
    {
      icon: DocumentIcon,
      text: 'Документы',
      routeName: routesNames.documents,
      disabled: (mainInfo.value?.tariff?.id === ETariffsId.FOR_KEY &&
        !mainInfo.value?.is_form_complete) &&
        role.value === ERole.USER
    },
    {
      icon: LocationIcon,
      text: 'Статус',
      routeName: null,
      disabled: true
    }
  ]
})

const mobileMenu = ref<HTMLElement | null>(null)
const useMobileMenu = (() => {
  const isOpened = ref(false)
  function toggle (value?: boolean) { isOpened.value = value ?? !isOpened.value }
  return { state: reactive({ isOpened }), toggle }
})()

onMounted(getMainInfo)

onClickOutside(mobileMenu, () => useMobileMenu.state.isOpened && useMobileMenu.toggle(false))

</script>

<style scoped lang="scss">
.route__icon {
  filter: invert(51%) sepia(2%) saturate(19%) hue-rotate(325deg) brightness(100%) contrast(81%);
}

.active-route {
  & .route__icon{
    filter: invert(10%) sepia(43%) saturate(1245%) hue-rotate(188deg) brightness(96%) contrast(111%);
  }

  & .route__text {
    @apply text-primary;
  }
}

.close-icon {
  filter: invert(52%) sepia(96%) saturate(2736%) hue-rotate(190deg) brightness(106%) contrast(94%);
}

.fade {
  &-top-right{
    &-move, &-enter-active {
      transition: all 200ms linear;
    }

    &-leave-active{
      transition: all 100ms linear;
    }

    &-enter-from {
      transform: translate(50%);
      opacity: 0;
    }

    &-leave-to {
      opacity: 0;
      transform: translate(50%);
    }
  }
}
</style>
