import { ESurveySteps, type IFormPayload, type TOnlyFillableSteps } from '@/types/shared'

export const stepOrder = {
  [ESurveySteps.STEP_ONE]: 1,
  [ESurveySteps.STEP_TWO]: 2,
  [ESurveySteps.STEP_THREE]: 3,
  [ESurveySteps.STEP_FOUR]: 4,
  [ESurveySteps.STEP_FIVE]: 5,
  [ESurveySteps.STEP_SIX]: 6,
  [ESurveySteps.STEP_SEVEN]: 7,
  [ESurveySteps.STEP_EIGHT]: 8,
  [ESurveySteps.STEP_NINE]: 9,
  [ESurveySteps.STEP_TEN]: 10,
  [ESurveySteps.STEP_ELEVEN]: 11,
  [ESurveySteps.STEP_TWELVE]: 12,
  [ESurveySteps.STEP_THIRTEEN]: 13,
  [ESurveySteps.STEP_FOURTEEN]: 14,
  [ESurveySteps.STEP_FIFTEEN]: 15,
  [ESurveySteps.STEP_SIXTEEN]: 16,
  [ESurveySteps.STEP_SEVENTEEN]: 17,
  [ESurveySteps.STEP_EIGHTEEN]: 18,
  [ESurveySteps.STEP_NINETEEN]: 19,
  [ESurveySteps.STEP_TWENTY]: 20,
  [ESurveySteps.STEP_TWENTY_ONE]: 21,
  [ESurveySteps.STEP_TWENTY_TWO]: 22,
  [ESurveySteps.STEP_TWENTY_THREE]: 23,
  [ESurveySteps.STEP_TWENTY_FOUR]: 24,
  [ESurveySteps.STEP_TWENTY_FIVE]: 25,
  [ESurveySteps.STEP_TWENTY_SIX]: 26
} satisfies Record<TOnlyFillableSteps, number>

export function stepTextInformation (isMarried: boolean): Record<TOnlyFillableSteps, { title: string }> {
  return {
    [ESurveySteps.STEP_ONE]: { title: 'Шаг 1: Личные данные' },
    [ESurveySteps.STEP_TWO]: { title: 'Шаг 2: Данные о работе' },
    [ESurveySteps.STEP_THREE]: { title: 'Шаг 3: Общая информация' },
    [ESurveySteps.STEP_FOUR]: { title: 'Шаг 4: Семейное положение' },
    [ESurveySteps.STEP_FIVE]: { title: 'Шаг 5: Кредиторы' },
    [ESurveySteps.STEP_SIX]: { title: 'Шаг 6: Алименты' },
    [ESurveySteps.STEP_SEVEN]: { title: 'Шаг 7: Причинение вреда' },
    [ESurveySteps.STEP_EIGHT]: { title: 'Шаг 8: Транспорт' },
    [ESurveySteps.STEP_NINE]: { title: 'Шаг 9: Оружие' },
    [ESurveySteps.STEP_TEN]: { title: 'Шаг 10: Недвижимость' },
    [ESurveySteps.STEP_ELEVEN]: { title: 'Шаг 11: Интеллектуальная собственность' },
    [ESurveySteps.STEP_TWELVE]: { title: 'Шаг 12: Ценные бумаги' },
    [ESurveySteps.STEP_THIRTEEN]: { title: 'Шаг 13: Банковский счет' },
    [ESurveySteps.STEP_FOURTEEN]: { title: 'Шаг 14: Наличные денежные средства' },
    [ESurveySteps.STEP_FIFTEEN]: { title: 'Шаг 15: Предметы роскоши' },
    [ESurveySteps.STEP_SIXTEEN]: { title: 'Шаг 16: Имущество для профессиональной деятельности' },
    [ESurveySteps.STEP_SEVENTEEN]: { title: 'Шаг 17: Доли в уставном капитале юридического лица' },
    [ESurveySteps.STEP_EIGHTEEN]: { title: 'Шаг 18: Ваши должники' },
    [ESurveySteps.STEP_NINETEEN]: { title: 'Шаг 19: Сделки за последние 3 года' },
    [ESurveySteps.STEP_TWENTY]: {
      title: isMarried ? 'Шаг 20: Имущество супругов' : 'Шаг 20: Имущество покойных супругов'
    },
    [ESurveySteps.STEP_TWENTY_ONE]: { title: 'Шаг 21: Оружие супругов' },
    [ESurveySteps.STEP_TWENTY_TWO]: { title: 'Шаг 22: Недвижимость супругов' },
    [ESurveySteps.STEP_TWENTY_THREE]: { title: 'Шаг 23: Интеллектуальная собственность супругов и ценные бумаги' },
    [ESurveySteps.STEP_TWENTY_FOUR]: { title: 'Шаг 24: Банковский вклад супругов' },
    [ESurveySteps.STEP_TWENTY_FIVE]: { title: 'Шаг 25: Доли супругов в юридических лицах' },
    [ESurveySteps.STEP_TWENTY_SIX]: { title: 'Шаг 26: Сделки супругов за последние 3 года' }
  }
}

export const nextSurveyStep = (isMarried: boolean, isWidow: boolean): Partial<Record<ESurveySteps, ESurveySteps>> => {
  const sharedSteps = {
    [ESurveySteps.HAS_FILLED_SURVEY]: ESurveySteps.STEP_ONE,
    [ESurveySteps.NO_ACTIVE_SURVEY]: ESurveySteps.STEP_ONE,
    [ESurveySteps.STEP_ONE]: ESurveySteps.STEP_TWO,
    [ESurveySteps.STEP_TWO]: ESurveySteps.STEP_THREE,
    [ESurveySteps.STEP_THREE]: ESurveySteps.STEP_FOUR,
    [ESurveySteps.STEP_FOUR]: ESurveySteps.STEP_FIVE,
    [ESurveySteps.STEP_FIVE]: ESurveySteps.STEP_SIX,
    [ESurveySteps.STEP_SIX]: ESurveySteps.STEP_SEVEN,
    [ESurveySteps.STEP_SEVEN]: ESurveySteps.STEP_EIGHT,
    [ESurveySteps.STEP_EIGHT]: ESurveySteps.STEP_NINE,
    [ESurveySteps.STEP_NINE]: ESurveySteps.STEP_TEN,
    [ESurveySteps.STEP_TEN]: ESurveySteps.STEP_ELEVEN,
    [ESurveySteps.STEP_ELEVEN]: ESurveySteps.STEP_TWELVE,
    [ESurveySteps.STEP_TWELVE]: ESurveySteps.STEP_THIRTEEN,
    [ESurveySteps.STEP_THIRTEEN]: ESurveySteps.STEP_FOURTEEN,
    [ESurveySteps.STEP_FOURTEEN]: ESurveySteps.STEP_FIFTEEN,
    [ESurveySteps.STEP_FIFTEEN]: ESurveySteps.STEP_SIXTEEN,
    [ESurveySteps.STEP_SIXTEEN]: ESurveySteps.STEP_SEVENTEEN,
    [ESurveySteps.STEP_SEVENTEEN]: ESurveySteps.STEP_EIGHTEEN,
    [ESurveySteps.STEP_EIGHTEEN]: ESurveySteps.STEP_NINETEEN
  }
  if (!isMarried && !isWidow) {
    return {
      ...sharedSteps,
      [ESurveySteps.STEP_NINETEEN]: ESurveySteps.STEP_FINAL
    }
  } else {
    if (isWidow && !isMarried) {
      return {
        ...sharedSteps,
        [ESurveySteps.STEP_NINETEEN]: ESurveySteps.STEP_TWENTY,
        [ESurveySteps.STEP_TWENTY]: ESurveySteps.STEP_FINAL
      }
    } else {
      return {
        ...sharedSteps,
        [ESurveySteps.STEP_NINETEEN]: ESurveySteps.STEP_TWENTY,
        [ESurveySteps.STEP_TWENTY]: ESurveySteps.STEP_TWENTY_ONE,
        [ESurveySteps.STEP_TWENTY_ONE]: ESurveySteps.STEP_TWENTY_TWO,
        [ESurveySteps.STEP_TWENTY_TWO]: ESurveySteps.STEP_TWENTY_THREE,
        [ESurveySteps.STEP_TWENTY_THREE]: ESurveySteps.STEP_TWENTY_FOUR,
        [ESurveySteps.STEP_TWENTY_FOUR]: ESurveySteps.STEP_TWENTY_FIVE,
        [ESurveySteps.STEP_TWENTY_FIVE]: ESurveySteps.STEP_TWENTY_SIX,
        [ESurveySteps.STEP_TWENTY_SIX]: ESurveySteps.STEP_FINAL
      }
    }
  }
}

export const previousSurveyStep: Record<ESurveySteps, ESurveySteps> = {
  [ESurveySteps.NO_ACTIVE_SURVEY]: ESurveySteps.NO_ACTIVE_SURVEY,
  [ESurveySteps.STEP_ONE]: ESurveySteps.STEP_ONE,
  [ESurveySteps.STEP_TWO]: ESurveySteps.STEP_ONE,
  [ESurveySteps.STEP_THREE]: ESurveySteps.STEP_TWO,
  [ESurveySteps.STEP_FOUR]: ESurveySteps.STEP_THREE,
  [ESurveySteps.STEP_FIVE]: ESurveySteps.STEP_FOUR,
  [ESurveySteps.STEP_SIX]: ESurveySteps.STEP_FIVE,
  [ESurveySteps.STEP_SEVEN]: ESurveySteps.STEP_SIX,
  [ESurveySteps.STEP_EIGHT]: ESurveySteps.STEP_SEVEN,
  [ESurveySteps.STEP_NINE]: ESurveySteps.STEP_EIGHT,
  [ESurveySteps.STEP_TEN]: ESurveySteps.STEP_NINE,
  [ESurveySteps.STEP_ELEVEN]: ESurveySteps.STEP_TEN,
  [ESurveySteps.STEP_TWELVE]: ESurveySteps.STEP_ELEVEN,
  [ESurveySteps.STEP_THIRTEEN]: ESurveySteps.STEP_TWELVE,
  [ESurveySteps.STEP_FOURTEEN]: ESurveySteps.STEP_THIRTEEN,
  [ESurveySteps.STEP_FIFTEEN]: ESurveySteps.STEP_FOURTEEN,
  [ESurveySteps.STEP_SIXTEEN]: ESurveySteps.STEP_FIFTEEN,
  [ESurveySteps.STEP_SEVENTEEN]: ESurveySteps.STEP_SIXTEEN,
  [ESurveySteps.STEP_EIGHTEEN]: ESurveySteps.STEP_SEVENTEEN,
  [ESurveySteps.STEP_NINETEEN]: ESurveySteps.STEP_EIGHTEEN,
  [ESurveySteps.STEP_TWENTY]: ESurveySteps.STEP_NINETEEN,
  [ESurveySteps.STEP_TWENTY_ONE]: ESurveySteps.STEP_TWENTY,
  [ESurveySteps.STEP_TWENTY_TWO]: ESurveySteps.STEP_TWENTY_ONE,
  [ESurveySteps.STEP_TWENTY_THREE]: ESurveySteps.STEP_TWENTY_TWO,
  [ESurveySteps.STEP_TWENTY_FOUR]: ESurveySteps.STEP_TWENTY_THREE,
  [ESurveySteps.STEP_TWENTY_FIVE]: ESurveySteps.STEP_TWENTY_FOUR,
  [ESurveySteps.STEP_TWENTY_SIX]: ESurveySteps.STEP_TWENTY_FIVE,
  [ESurveySteps.STEP_FINAL]: ESurveySteps.STEP_FINAL,
  [ESurveySteps.HAS_FILLED_SURVEY]: ESurveySteps.HAS_FILLED_SURVEY
}

export const payloadStepKeys: Record<TOnlyFillableSteps, keyof IFormPayload> = {
  [ESurveySteps.STEP_ONE]: 'step1',
  [ESurveySteps.STEP_TWO]: 'step2',
  [ESurveySteps.STEP_THREE]: 'step3',
  [ESurveySteps.STEP_FOUR]: 'step4',
  [ESurveySteps.STEP_FIVE]: 'step5',
  [ESurveySteps.STEP_SIX]: 'step6',
  [ESurveySteps.STEP_SEVEN]: 'step7',
  [ESurveySteps.STEP_EIGHT]: 'step8',
  [ESurveySteps.STEP_NINE]: 'step9',
  [ESurveySteps.STEP_TEN]: 'step10',
  [ESurveySteps.STEP_ELEVEN]: 'step11',
  [ESurveySteps.STEP_TWELVE]: 'step12',
  [ESurveySteps.STEP_THIRTEEN]: 'step13',
  [ESurveySteps.STEP_FOURTEEN]: 'step14',
  [ESurveySteps.STEP_FIFTEEN]: 'step15',
  [ESurveySteps.STEP_SIXTEEN]: 'step16',
  [ESurveySteps.STEP_SEVENTEEN]: 'step17',
  [ESurveySteps.STEP_EIGHTEEN]: 'step18',
  [ESurveySteps.STEP_NINETEEN]: 'step19',
  [ESurveySteps.STEP_TWENTY]: 'step20',
  [ESurveySteps.STEP_TWENTY_ONE]: 'step21',
  [ESurveySteps.STEP_TWENTY_TWO]: 'step22',
  [ESurveySteps.STEP_TWENTY_THREE]: 'step23',
  [ESurveySteps.STEP_TWENTY_FOUR]: 'step24',
  [ESurveySteps.STEP_TWENTY_FIVE]: 'step25',
  [ESurveySteps.STEP_TWENTY_SIX]: 'step26'
}
